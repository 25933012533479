import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"
import ReactDisqusComments from "react-disqus-comments"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Layout from "../../components/layout"
import classes from "./article.module.scss"
import SEO from "../../components/seo"
const Article = ({ data }) => {
  let disqusConfig = {
    url: `${
      "https://www.harp0n.pl/blog/post/" +
      data.strapiWpis.link
    }`,
    identifier: data.strapiWpis.id,
    title: data.strapiWpis.Tytul,
  }
  const cutDescription = description => {
    const words_limit = 200; 
    let re =/[*#\n]+/
    return description.length < words_limit
      ? description.split(re).join(' ')
      : description.split(re).join(' ').substr(0,words_limit) + "..."
  }
  return (
    <Layout>
      <SEO title={data.strapiWpis.Tytul} description={cutDescription(data.strapiWpis.Tekst)} keywords={data.strapiWpis.tagi}  />
      <article className={classes.article}>
        <h1 className={classes.title}>{data.strapiWpis.Tytul}</h1>
        <div className={classes.description}>
          <Img fadeIn={false} loading="eager"
            fluid={data.strapiWpis.Obraz.childImageSharp.fluid}
            className={classes.image}
          />
          <ReactMarkdown
            transformImageUri={uri =>
              uri.startsWith("http")
                ? uri
                : `${process.env.IMAGE_BASE_URL}${uri}`
            }
            className={classes.text}
          >
            {data.strapiWpis.Tekst}
          </ReactMarkdown>
        </div>
        <Link to="/blog" className={classes.link}>
          <span className={classes.icon}><ArrowBackIcon /></span> powrót do listy
        </Link>

        <div className={classes.disqus}>
          <ReactDisqusComments
            shortname="pablo-11"
            identifier={disqusConfig.identifier}
            title={disqusConfig.title}
            url={disqusConfig.url}
          />
        </div>
      </article>
    </Layout>
  )
}

Article.propTypes = {}

export default Article

export const query = graphql`
  query ArticleTemplate($id: String!) {
    strapiWpis(id: { eq: $id }) {
      id
      link
      Tekst
      Tytul
      tagi
      Obraz {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
